.eventStats {
	display: flex;
	position: relative;
	flex-direction: column;
	background-color: #fff;
	border-radius: 32px;
	width: 100%;
	height: 100%;
	&__close {
		position: absolute;
		top: 20px;
		right: 20px;
		svg {
			path {
				fill: var(--black);
			}
		}
	}
	&__header {
		align-self: center;
		margin-top: 20px;
		h4 {
			font-family: 'Kumbh';
			font-weight: 400;
			font-size: 16px;
			text-align: center;
		}
		span {
			font-weight: 400;
			font-size: 32px;
			line-height: 137%;
		}
	}
	&__yourTaps {
		display: flex;
		flex-direction: column	;
		justify-content: center;
		align-items: center;
	}
	&__eventInfo {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 32px 32px 0 0;
		background-color: #000;
		color: #fff;
		margin-top: 12px;
		padding: 23px 17px;
		gap: 10px;
	}
	&__info {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		margin-bottom: 12px;
		& > :nth-child(1) {
			display: flex;
			width: 24px;
			height: 24px;
			margin-right: 10px;
		}
		& > :nth-child(2) {
			display: flex;
			font-family: 'Kumbh';
		}
		& > :nth-child(3) {
			flex: 1;
			display: flex;
			justify-content: flex-end;
		}
	}
}