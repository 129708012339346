.qrComponent {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: #FFF500;
  &__text {
    text-align: center;
    margin-top: 15px;
    h4 {
      font-size: 16px;
    }
  }
  &__img {
    width: 90vmin; // Ширина зависит от меньшей стороны вьюпорта
	height: 90vmin; // Высота равна ширине, чтобы сохранить пропорции
	display: flex;
	justify-content: center;
	align-items: center;
  margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain; // Масштабируем изображение, сохраняя пропорции
    }
  }
}