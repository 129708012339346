.eventWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	justify-content: space-around;
	.event__sessionText {
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 18px;
		text-align: center;
	}
	.event__clickArea {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 315px;
    width: 100%;
    z-index: 75;
	}
	.event__ripple {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		background-color: rgba(128, 128, 128, 0.3);
		border-radius: 50%;
		animation: ripple-animation 2s infinite;
		z-index: 0;
	}
	.event__ripple:nth-child(2) {
		
		animation-delay: 1s;
	  }
	  
	  .clicker__ripple:nth-child(3) {
		animation-delay: 2s;
	  }
	  
	  @keyframes ripple-animation {
		0% {
		  transform: scale(0.8);
		  opacity: 0;
		}
		100% {
		  transform:  scale(1.5);
		  opacity: 1;
		}
	  }
	.event__clickValue {
		display: block;
		align-items: center;
		flex-direction: row;
		img {
			width: 50px;
		}
	}
	.event__clickerHero {
		display: flex;
		align-self: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		z-index: 5;
		position: relative;
	}
	.event__progressBox {
		display: flex;	
		position: absolute;
		height: 90%;
		width: 90%;
	}
	.event__tip {
		display: flex;
		align-items: center;
		text-align: center;
		font-family: "Kumbh";
		margin-top: 10px;
		font-weight: 400;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.5);
		svg {
			margin-left:4px;
		}
	}
	@media (max-height: 686px) {
		.event__clickArea {
			height: 237px;
			width: 100%;
		}
	}	
}
.event {	
	&__eventVotes {
		display: flex;
    justify-content: center;
    width: 100%;
    height: 48px;
    align-items: center;
    border: 2px solid #000;
    border-radius: 44px;
    width: auto;
    padding: 0px 20px 0px 20px;
	}
	&__eventVotes-left, &__eventVotes-right {
		display: flex;
		flex-direction: column;
		align-items: center;
	
	}
	&__eventVotes-left {
		border-right: 1px solid #abb0bc;
		padding: 0 14px 0 0;
	}
	&__eventVotes-right {
		padding: 0 0 0 14px;
	}
	&__eventVotes-text {
		font-family: "Kumbh";
		font-weight: 400;
		font-size: 12px;
	}
	&__eventVotes-number {
		display: flex;
		align-items: center;
		font-family: "Kumbh";
		font-weight: 700;
		font-size: 18px;
		color: #000;
		span {
			margin-right: 2px;
		}
		svg {
			width: 14px;
			height: 14px;
		}

	}
}